import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Link, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"faq"} />
		<Helmet>
			<title>
				FAQ - FAQ chez Speedy Motorbike Workshop
			</title>
			<meta name={"description"} content={"Chez Speedy, nous comprenons le plaisir de la conduite et l'importance de la confiance dans votre atelier. "} />
			<meta property={"og:title"} content={"FAQ - FAQ chez Speedy Motorbike Workshop"} />
			<meta property={"og:description"} content={"Chez Speedy, nous comprenons le plaisir de la conduite et l'importance de la confiance dans votre atelier. "} />
			<meta property={"og:image"} content={"https://parischics.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://parischics.com/img/dll2.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://parischics.com/img/dll2.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://parischics.com/img/dll2.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://parischics.com/img/dll2.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://parischics.com/img/dll2.png"} />
			<meta name={"msapplication-TileImage"} content={"https://parischics.com/img/dll2.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="100px 0 100px 0" background="#ffffff" quarkly-title="FAQ-9">
			<Text margin="0px 0px 70px 0px" font="normal 600 42px/1.2 --fontFamily-sans" color="--darkL1" sm-margin="0px 0px 50px 0px">
				FAQ chez Speedy{" "}
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				grid-template-columns="1fr"
				grid-gap="50px 50px"
				lg-grid-template-columns="repeat(2, 1fr)"
				md-grid-template-columns="1fr"
				sm-grid-gap="35px 0"
				flex-direction="column"
			>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="25px 0px 0px 0px"
					border-width="1px 0 0 0"
					border-style="solid"
					border-color="--color-lightD2"
					md-flex-direction="column"
				>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 25px/1.2 --fontFamily-sans"
						color="--darkL1"
						width="40%"
						md-width="100%"
					>
						Dois-je prendre rendez-vous ?
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
						color="#555a5f"
						width="60%"
						padding="0px 0px 0px 50px"
						md-width="100%"
						md-padding="0px 0px 0px 0"
					>
						Oui, nous recommandons de prendre rendez-vous pour que votre moto reçoive l'attention qu'elle mérite.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="25px 0px 0px 0px"
					border-width="1px 0 0 0"
					border-style="solid"
					border-color="--color-lightD2"
					md-flex-direction="column"
				>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 25px/1.2 --fontFamily-sans"
						color="--darkL1"
						width="40%"
						md-width="100%"
					>
						Quels types de motos entretenez-vous ?
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
						color="#555a5f"
						width="60%"
						padding="0px 0px 0px 50px"
						md-width="100%"
						md-padding="0px 0px 0px 0"
					>
						Nous nous occupons d'une large gamme de motos, des cruisers aux motos sportives en passant par les classiques.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="25px 0px 0px 0px"
					border-width="1px 0 0 0"
					border-style="solid"
					border-color="--color-lightD2"
					md-flex-direction="column"
				>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 25px/1.2 --fontFamily-sans"
						color="--darkL1"
						width="40%"
						md-width="100%"
					>
						Puis-je me procurer mes propres pièces pour que vous les installiez ?
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
						color="#555a5f"
						width="60%"
						padding="0px 0px 0px 50px"
						md-width="100%"
						md-padding="0px 0px 0px 0"
					>
						Bien que nous préférions utiliser nos fournisseurs de confiance, nous pouvons travailler avec des pièces que vous nous fournissez après un contrôle de qualité.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="25px 0px 0px 0px"
					border-width="1px 0 0 0"
					border-style="solid"
					border-color="--color-lightD2"
					md-flex-direction="column"
				>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 25px/1.2 --fontFamily-sans"
						color="--darkL1"
						width="40%"
						md-width="100%"
					>
						Offrez-vous une garantie sur vos services ?
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
						color="#555a5f"
						width="60%"
						padding="0px 0px 0px 50px"
						md-width="100%"
						md-padding="0px 0px 0px 0"
					>
						Absolument, tous les services sont assortis d'une garantie de satisfaction et des garanties applicables.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="25px 0px 0px 0px"
					border-width="1px 0 0 0"
					border-style="solid"
					border-color="--color-lightD2"
					md-flex-direction="column"
				>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 25px/1.2 --fontFamily-sans"
						color="--darkL1"
						width="40%"
						md-width="100%"
					>
						À quelle fréquence dois-je faire réviser mon vélo ?
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
						color="#555a5f"
						width="60%"
						padding="0px 0px 0px 50px"
						md-width="100%"
						md-padding="0px 0px 0px 0"
					>
						La fréquence d'entretien varie selon le modèle, mais nous suggérons un contrôle tous les 5 000 miles ou au moins une fois par an.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="25px 0px 0px 0px"
					border-width="1px 0 0 0"
					border-style="solid"
					border-color="--color-lightD2"
					md-flex-direction="column"
				>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 25px/1.2 --fontFamily-sans"
						color="--darkL1"
						width="40%"
						md-width="100%"
					>
						Proposez-vous des services de modification sur mesure ?
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
						color="#555a5f"
						width="60%"
						padding="0px 0px 0px 50px"
						md-width="100%"
						md-padding="0px 0px 0px 0"
					>
						Oui, nous nous spécialisons dans les modifications personnalisées afin d'améliorer les performances et l'esthétique de votre véhicule.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 0px"
				justify-content="center"
				lg-order="1"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline2"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Vous avez encore des questions ?
				</Text>
				<Text margin="0px 0px 40px 0px" color="--greyD3" font="--lead" lg-text-align="center">
					Notre équipe se tient à votre disposition pour vous fournir toutes les informations complémentaires dont vous avez besoin. N'hésitez pas à nous contacter pour obtenir des réponses à vos questions.
				</Text>
				<Link
					href="/contacts"
					padding="12px 24px 12px 24px"
					color="--light"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 16px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					sm-margin="0px 0px 16px 0px"
					sm-text-align="center"
					background="--color-primary"
					hover-transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-primary"
				>
					Contacts
				</Link>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="flex-end"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 32px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 32px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
			>
				<Image
					src="https://parischics.com/img/7.jpg"
					object-fit="cover"
					width="100%"
					height="100%"
					border-radius="24px"
					transition="transform 0.2s ease-in-out 0s"
					max-width="480px"
					max-height="480px"
				/>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});